import React from "react";
import {
  SEO,
  PageLayout,
  Map,
  Location,
  HoursOfOperation,
} from "@bluefin/components";
import { Grid, Header } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";

export default class ContactPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
        />
        <PageLayout
          className={"contact-page"}
          hero={
            <Map
              address={{
                street: fishermanBusiness.primaryLocation.street,
                city: fishermanBusiness.primaryLocation.city,
                state: fishermanBusiness.primaryLocation.state,
                zip: fishermanBusiness.primaryLocation.zipCode,
              }}
            />
          }
        >
          <Grid stackable={true}>
            <Grid.Column width={4} />
            <Grid.Column width={8} textAlign={"center"}>
              <Header as={"h5"}>Contact Info</Header>
              <Location
                businessName={fishermanBusiness.name}
                phone={fishermanBusiness.primaryLocation.phoneNumber}
                address={{
                  street: fishermanBusiness.primaryLocation.street,
                  city: fishermanBusiness.primaryLocation.city,
                  state: fishermanBusiness.primaryLocation.state,
                  zip: fishermanBusiness.primaryLocation.zipCode,
                }}
              />
              <HoursOfOperation
                hours={fishermanBusiness.primaryLocation.hours}
                displayOption={"grouped"}
              />
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      primaryLocation {
        street
        city
        state
        zipCode
        phoneNumber
        hours {
          day
          open
          close
          label
        }
      }
    }
    fishermanBusinessWebsitePage(pageType: { eq: "Contact" }) {
      title
    }
  }
`;
